import React from 'react';
import Select from 'react-select'

const BookingTable = ({bookings, nextBookingId, onUpdateBooking}) => {

    const handleEdit = (index, field, value) => {
        const newBookings = [...bookings];
        newBookings[index] = {...newBookings[index], [field]: value};
        onUpdateBooking(newBookings);
    };

    const handleSubValueEdit = (index, field, field2, value) => {
        const newBookings = [...bookings];
        newBookings[index][field][field2] = value;
        onUpdateBooking(newBookings);
    }

    const bookingTypeLabels = [
        {value: 1, label: 'Sponsoring'},
        {value: 3, label: 'Exclusive'},
        {value: 6, label: 'Standard High'},
        {value: 8, label: 'Standard Normal'},
        {value: 10, label: 'Standard Low'},
        {value: 16, label: 'In-House'},
    ];

    const renderBookingType = (type) => {
        switch (type) {
            case 1:
                return 'Sponsoring';
            case 3:
                return 'Exclusive';
            case 6:
                return 'Standard High';
            case 8:
                return 'Standard Normal';
            case 10:
                return 'Standard Low';
            case 16:
                return 'In-House';
            default:
                return 'Unknown';
        }
    };

    const allBookingIds = bookings.map(booking => {
        return {
            value: booking.id,
            label: booking.id
        }
    });

    const handleAdd = () => {
        const newBookings = [...bookings];

        let newBooking = {
            "id": nextBookingId,
            "bookingType": 1,
            "space": "sky",
            "deliveryFrequency": "even",
            "deliveryPerPage": "",
            "creativeSwitch": "",
            "from": "2023-01-01",
            "to": "2023-12-31",
            "amount": 10000,
            "delivered": 5000,
            "tags": {
                "cat_id": "cat123",
                "post_id": "post1",
                "page_type": "type2",
                "url": "https://"
            },
            "region": "DE",
            "price": 110,
            "frequencyCapping": 0,
            "exclusionOfCompetition": [],
            "adImage": "http://111111",
            "currentUserViews": 0
        };

        newBookings.push(newBooking);

        onUpdateBooking(newBookings);
    }

    return (
        <div>
            <table>
                <thead>
                <tr>
                    <td>#</td>
                    <td>Buchungstyp</td>
                    <td>Werbeplatz</td>
                    <td>Auslieferungszeitraum</td>
                    <td>Menge</td>
                    <td>Menge ausgeliefert</td>
                    <td>Targeting Tags</td>
                    <td>Targeting Region</td>
                    <td>Preis</td>
                    <td>Frequency Capping</td>
                    <td>Konkurrenzausschluss</td>
                    <td>Bild URL</td>
                    <td>Benutzeransichten</td>
                </tr>
                </thead>
                <tbody>
                {bookings.map((d, index) => {
                    const exclusionOfCompetition = (bookings[index]?.exclusionOfCompetition || d.exclusionOfCompetition).map(e => {
                        return {
                            value: e,
                            label: e
                        }
                    });

                    return (
                        <tr key={index} className={'booking-type-' + d.space}>
                            <td>{d.id}</td>
                            <td>
                                <Select
                                    options={bookingTypeLabels}
                                    defaultValue={{
                                        value: parseInt(bookings[index]?.bookingType || d.bookingType),
                                        label: renderBookingType(parseInt(bookings[index]?.bookingType || d.bookingType))
                                    }}
                                    onChange={(e) => {
                                        handleEdit(index, 'bookingType', parseInt(e.value));
                                    }}
                                />
                            </td>
                            <td>
                                <Select
                                    options={
                                        [
                                            {value: 'sky', label: 'sky'},
                                            {value: 'bigsize', label: 'bigsize'},
                                            {value: 'content', label: 'content'},
                                            {value: 'shortcode', label: 'shortcode'},
                                        ]
                                    }
                                    defaultValue={{
                                        'value': bookings[index]?.space || d.space,
                                        'label': bookings[index]?.space || d.space
                                    }}
                                    onChange={(e) => {
                                        handleEdit(index, 'space', e.value);
                                    }}
                                />
                            </td>
                            <td>
                                <input
                                    type="date"
                                    value={bookings[index]?.from || d.from}
                                    onChange={(e) => handleEdit(index, 'from', e.target.value)}
                                />
                                <input
                                    type="date"
                                    value={bookings[index]?.to || d.to}
                                    onChange={(e) => handleEdit(index, 'to', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bookings[index]?.amount || d.amount}
                                    onChange={(e) => handleEdit(index, 'amount', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bookings[index]?.delivered || d.delivered}
                                    onChange={(e) => handleEdit(index, 'delivered', e.target.value)}
                                />
                            </td>
                            <td>
                                {

                                    Object.keys(bookings[index]?.tags || d.tags).map((tag, index2) => (
                                        <div key={index2}>
                                            {tag}:
                                            <input
                                                className={tag}
                                                type="text"
                                                value={bookings[index].tags[tag]}
                                                onChange={(e) => handleSubValueEdit(index, 'tags', tag, e.target.value)}
                                            />
                                        </div>
                                    ))
                                }
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={bookings[index]?.region || d.region}
                                    onChange={(e) => handleEdit(index, 'region', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bookings[index]?.price || d.price}
                                    onChange={(e) => handleEdit(index, 'price', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bookings[index]?.frequencyCapping || d.frequencyCapping}
                                    onChange={(e) => handleEdit(index, 'frequencyCapping', e.target.value)}
                                />
                            </td>
                            <td>
                                <Select
                                    isMulti
                                    isClearable
                                    isSearchable
                                    options={allBookingIds}
                                    onChange={(e) => {
                                        const values = e.map(value => parseInt(value.value));
                                        handleEdit(index, 'exclusionOfCompetition', values);
                                    }}
                                    defaultValue={exclusionOfCompetition}/>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={bookings[index]?.adImage || d.adImage}
                                    onChange={(e) => handleEdit(index, 'adImage', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={bookings[index]?.currentUserViews || d.currentUserViews}
                                    onChange={(e) => handleEdit(index, 'currentUserViews', e.target.value)}
                                />
                            </td>
                        </tr>
                    );
                })}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={16}>
                        <button onClick={handleAdd}>Add</button>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default BookingTable;
