import React from 'react';
import BookingTable from "./BookingTable";
import Select from "react-select";

const Orders = ({orders, onUpdateOrder}) => {

    const handleUpdateBookings = (orderId, newBookings) => {
        // find order with id order.id in orders and make a copy of it
        const orderToUpdate = {...orders.find(o => o.orderId === orderId)};

        // update bookings in order
        orderToUpdate.bookings = newBookings;

        // copy orders
        const newOrders = [...orders];

        // replace order with updated order
        newOrders[orders.findIndex(o => o.orderId === orderId)] = orderToUpdate;

        // trigger update orders in parent component
        onUpdateOrder(newOrders);

    };

    const handleAdd = () => {
        const newOrders = [...orders];

        // get max id
        const newId = Math.max(0, Math.max(...newOrders.map(order => order.orderId)));

        newOrders.push({
            orderId: newId + 1,
            orderName: `order${newId + 1}`,
            bookings: []
        });

        onUpdateOrder(newOrders);
    }

    const handleDelete = orderId => {
        const newOrders = [...orders];
        newOrders.splice(orders.findIndex(o => o.orderId === orderId), 1);
        onUpdateOrder(newOrders);
    }

    // get the highest booking.id of bookings in all orders
    const getHighestBookingId = () => {
        const max = Math.max(...orders.map(order => {
            return Math.max(...order.bookings.map(booking => {
                return parseInt(booking.id);
            }));
        }));

        return Math.max(max, 0);
    }

    const highestBookingId = getHighestBookingId();

    const deliveryPerPageOptions = [
        {value: '', label: '-'},
        {value: 'one', label: 'nur eines'},
        {value: 'one-or-more', label: 'eines oder mehrere'},
        {value: 'as-much-as-possible', label: 'so viel wie möglich'},
    ];

    const deliveryFrequencyOptions = [
        {value: '', label: '-'},
        {value: 'even', label: 'gleichmäßig'},
        {value: 'initially-heavy', label: 'anfangslastig'},
        {value: 'as-fast-as-possible', label: 'schnellstmöglich'},
    ];

    const creativeSwitchOptions = [
        {value: '', label: '-'},
        {value: 'even', label: 'gleichmäßig'},
        {value: 'optimized', label: 'optimiert'},
        {value: 'weighted', label: 'gewichtet'},
    ];

    const handleEditOrder = (fieldName, orderId, value) => {
        const newOrders = [...orders];
        newOrders[orders.findIndex(o => o.orderId === orderId)][fieldName] = value;
        onUpdateOrder(newOrders);
    };

    return <table className="orders">
        <thead>
        <tr>
            <th>Auftrag ID</th>
            <th>Auftragsbezeichnung</th>
            <th>Buchungstabelle</th>
        </tr>
        </thead>
        <tbody>
        {orders.map(order => {
            const deliveryPerPage = order.deliveryPerPage;
            const deliveryFrequency = order.deliveryFrequency;
            const creativeSwitch = order.creativeSwitch;

            return <tr key={order.orderId}>
                <td>
                    {order.orderId}
                </td>
                <td>
                    <input type="text"
                           value={order.orderName}
                           onChange={e => {
                               handleEditOrder('orderName', e.target.value);
                           }}
                    />
                    <button onClick={() => handleDelete(order.orderId)}>Auftrag löschen</button>
                </td>
                <td>
                    <div className="order-settings">
                        <div className="order-setting">
                            <div>Auslieferungsfrequenz</div>
                            <Select
                                options={deliveryFrequencyOptions}
                                defaultValue={{
                                    'value': deliveryFrequency,
                                    'label': deliveryFrequencyOptions.find(e => e.value === deliveryFrequency).label
                                }}
                                onChange={(e) => {
                                    handleEditOrder('deliveryFrequency', order.orderId, e.value);
                                }}
                            />
                        </div>
                        <div className="order-setting">
                            <div>Auslieferung pro Seite</div>
                            <Select
                                options={deliveryPerPageOptions}
                                defaultValue={{
                                    'value': deliveryPerPage,
                                    'label': deliveryPerPageOptions.find(e => e.value === deliveryPerPage).label
                                }}
                                onChange={(e) => {
                                    handleEditOrder('deliveryPerPage', order.orderId, e.value);
                                }}
                            />
                        </div>
                        <div className="order-setting">
                            <div>Creative Wechsel</div>
                            <Select
                                options={creativeSwitchOptions}
                                defaultValue={{
                                    'value': creativeSwitch,
                                    'label': creativeSwitchOptions.find(e => e.value === creativeSwitch).label
                                }}
                                onChange={(e) => {
                                    handleEditOrder('creativeSwitch', order.orderId, e.value);
                                }}
                            />
                        </div>
                    </div>
                    <BookingTable bookings={order.bookings}
                                  nextBookingId={highestBookingId + 1}
                                  onUpdateBooking={newBookings => handleUpdateBookings(order.orderId, newBookings)}/>
                </td>
            </tr>;
        })}
        </tbody>
        <tfoot>
        <tr>
            <td colSpan="3">
                <button onClick={handleAdd}>Auftrag erstellen</button>
            </td>
        </tr>
        </tfoot>
    </table>
}


export default Orders;