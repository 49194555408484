import React, {useState, useRef} from 'react';
import './App.css';
import Orders from "./Orders";

import orderDataFromFile from './data.json';

function App() {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [targetingTags, setTargetingTags] = useState([]);
    const [targetingRegion, setTargetingRegion] = useState("");
    const [orders, setOrders] = useState(orderDataFromFile);
    const [importData, setImportData] = useState(JSON.stringify(orderDataFromFile));
    const [accordions, setAccordions] = useState({});
    const currentAds = useRef({
        'sky': 0,
        'content': 0,
        'bigsize': 0,
        'shortcode': 0,
    });
    const loadOrder = useRef([]);

    const formatDate = date => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleUpdateOrder = newOrders => {
        // Hier werden die aktualisierten Daten von der BookingTable-Komponente empfangen
        currentAds.current = {
            'sky': 0,
            'content': 0,
            'bigsize': 0,
            'shortcode': 0,
        };
        setOrders(newOrders);
        setImportData(JSON.stringify(newOrders));
    };

    const getAdFor = adSpace => {
        // Example order:
        // {
        //     "orderId": 1,
        //     "orderName": "order1",
        //     "deliveryFrequency": "even",
        //     "deliveryPerPage": "",
        //     "creativeSwitch": "",
        //     "bookings": []
        // },

        //  Example booking:
        // {
        //     "bookingType": 10,
        //     "space": "bigsize",
        //     "from": "2023-01-01",
        //     "to": "2023-12-31",
        //     "amount": 10000,
        //     "delivered": 5000,
        //     "tags": {
        //       "cat_id": "",
        //       "post_id": "",
        //       "page_type": "",
        //       "url": ""
        //     },
        //     "region": "DE",
        //     "price": 210,
        //     "frequencyCapping": 0,
        //     "exclusionOfCompetition": false,
        //     "adImage": "http://11-11-11",
        //     "currentUserViews": 10
        //   }

        // fetch all bookings from all orders
        let filteredBookings = [...orders].reduce((acc, order) => {
            // add orderId to all elements in order.bookings
            order.bookings = order.bookings.map(booking => {
                booking.orderId = order.orderId;
                return booking;
            });
            return [...acc, ...order.bookings];
        }, []);

        // filter all bookings by space === adSpace
        filteredBookings = filteredBookings.filter(booking => booking.space === adSpace);

        // filter all bookings by date
        filteredBookings = filteredBookings.filter(booking => {
            const startDate = new Date(booking.from);
            const endDate = new Date(booking.to);

            return startDate <= currentDate && endDate >= currentDate;
        });

        // filter by impressions
        filteredBookings = filteredBookings.filter(booking => {
            return parseInt(booking.delivered) < parseInt(booking.amount);
        });

        // filter by targeting tags
        filteredBookings = filteredBookings.filter(booking => {
            return Object.keys(targetingTags).every(tag => {
                return booking.tags[tag] === targetingTags[tag];
            });
        });

        // filter by target Region
        // @todo get region from user/browser and find out if it is in the target region

        // sort by price (highest first)
        filteredBookings = filteredBookings.sort((a, b) => {
            return b.price - a.price;
        });

        // filter by frequencyCapping
        filteredBookings = filteredBookings.filter(booking => {
            const frequencyCappingNumber = parseInt(booking.frequencyCapping);

            if (frequencyCappingNumber <= 0) return true;

            // @todo get user/browser and find out if the user has seen the ad more than frequencyCappingNumber times
            return booking.currentUserViews < frequencyCappingNumber;
        });

        // now filter by exclusionOfCompetition
        filteredBookings = filteredBookings.filter(booking => {
            // check if the current booking cannot be displayed because another booking is already displayed (in currentAds.current)
            const exclusionList = booking.exclusionOfCompetition.map(bookingId => parseInt(bookingId));
            const currentAdsList = Object.values(currentAds.current);
            const isExcluded = exclusionList.some(bookingId => currentAdsList.includes(bookingId));

            return !isExcluded;
        });

        // now filter by deliveryPerPage
        filteredBookings = filteredBookings.filter(booking => {
            let currentAdsList;

            // find the order with the given orderId
            const order = orders.find(order => order.orderId === booking.orderId);

            switch (order.deliveryPerPage) {
                case 'one':
                    // This makes sure that only one booking from the same order is displayed at the same time

                    // check if the current booking cannot be displayed because another booking within the same order is already displayed (in currentAds.current)
                    currentAdsList = Object.values(currentAds.current).filter(bookingId => bookingId !== 0);

                    if (!currentAdsList) return true;
                    if (currentAdsList.length <= 0) return true;

                    // run through currentAdsList and check if they're from the same order
                    const isExcluded = currentAdsList.some(bookingId => {
                        const b = orders
                            .find(o => parseInt(o.orderId) === parseInt(order.orderId))
                            .bookings.find(b => parseInt(b.id) === parseInt(bookingId));

                        return !!b;
                    });

                    return !isExcluded;
                case 'one-or-more':
                    // add property "priority" +10 to one booking from the same order
                    filteredBookings = filteredBookings.map(b => {
                        if (b.orderId === booking.orderId && b.id === booking.id) {
                            b.priority += 10;
                        }
                        return b;
                    });
                    return true;
                case 'as-much-as-possible':
                    // add property "priority" +10 to all bookings from the same order
                    filteredBookings = filteredBookings.map(b => {
                        if (b.orderId === booking.orderId) {
                            b.priority += 10;
                        }
                        return b;
                    });
                    return true;
            }

            return true;
        });

        // randomize the order of the bookings
        filteredBookings = filteredBookings.sort(() => Math.random() - 0.5);

        // sort by priority (highest first)
        filteredBookings = filteredBookings.sort((a, b) => {
            return b.priority - a.priority;
        });

        // get first booking from the list if it exists
        const booking = filteredBookings[0] || null;
        const bookingId = booking ? booking.id : 0;

        let allCurrentAds = {...currentAds.current};
        allCurrentAds[adSpace] = parseInt(bookingId);
        currentAds.current = allCurrentAds;

        const accordionOpen = accordions[adSpace] || false;

        return <div>
            <p>{filteredBookings.length} Treffer (#{bookingId})</p>
            <div>
                <button
                    onClick={() => {
                        setAccordions({
                            ...accordions,
                            [adSpace]: !accordionOpen,
                        });
                    }}
                >{accordionOpen ? 'Verbergen' : 'Anzeigen'}</button>
                {accordionOpen &&
                    <pre>{JSON.stringify(filteredBookings, null, 2)}</pre>
                }
            </div>
        </div>;
    };

    loadOrder.current = [];

    return (
        <div className="App">
            <div className="parent">
                {
                    ['sky', 'bigsize', 'content', 'shortcode']
                        .sort(() => Math.random() - 0.5)
                        .map(adSpace => {
                                loadOrder.current.push(adSpace);
                                return <div key={adSpace} className={adSpace}>{adSpace}: {getAdFor(adSpace)}</div>
                            }
                        )
                }
            </div>

            <p>Ladereihenfolge: {loadOrder.current.join(', ')}</p>

            <div className="current-page-filter">
                <h2>Filter für aktuelle Seite</h2>
                <div>
                    <label htmlFor="now">Datum:</label>
                    <input
                        type="date"
                        id="now"
                        name="now"
                        value={formatDate(currentDate)} // Verknüpfung mit dem State für das Datum-Feld
                        onChange={(e) => {
                            setCurrentDate(new Date(e.target.value));
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="tags">Targeting Tags</label>
                    {['cat_id', 'post_id', 'page_type', 'url'].map((tag) => {
                        return (
                            <div key={tag}>
                                <label htmlFor={tag}>{tag}</label>
                                <input
                                    type="text"
                                    id={tag}
                                    value={targetingTags[tag] || ""}
                                    onChange={(e) => {
                                        setTargetingTags({
                                            ...targetingTags,
                                            [tag]: e.target.value,
                                        });
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <div>
                    <label htmlFor="region">Targeting Region</label>
                    <select
                        disabled={true}
                        id="region"
                        name="region"
                        value={targetingRegion} // Verknüpfung mit dem State für das Targeting-Region-Feld
                        onChange={(e) => setTargetingRegion(e.target.value)}
                    >
                        <option value="">Alle</option>
                        <option value="DE">DE</option>
                        <option value="AT">AT</option>
                        <option value="CH">CH</option>
                        <option value="BY">BY</option>
                    </select>{' '}
                    <small>Etwas komplexer weil Umkreissuche?</small>
                </div>
            </div>
            <div className="booking-table">
                <h2>Buchungstabelle</h2>
                <Orders orders={orders} onUpdateOrder={handleUpdateOrder}/>
            </div>
            <div className="import-data">
                <h2>Datenimport & -export</h2>
                <textarea
                    onChange={(e) => {
                        setImportData(e.target.value);
                    }}
                    placeholder="Datenimport" rows="25" cols="50" value={importData}/>
                <button
                    onClick={() => {
                        setOrders(JSON.parse(importData));
                    }}
                >Importieren
                </button>
            </div>
        </div>
    );
}

export default App;
